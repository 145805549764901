<template>
    <div
        class="
            absolute
            top-0
            left-0
            flex flex-col
            items-center
            w-full
            h-full
            select-none
            pointer-events-none
        "
        :class="{ 'text-lg': small, 'sm:text-2xl text-xl': !small }"
    >
        <div class="section-tag max-w-4xl">
            <span class="before"> &lt;{{ tag }}&gt;</span>
            <span class="after"> &lt;/{{ tag }}&gt;</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tag: {
            type: String,
            required: true,
        },
        small: {
            type: Boolean,
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.section-tag {
    @apply relative w-full mx-auto flex-grow;
    .before,
    .after {
        font-family: $cursive-font;
        color: $tag-color;
        @apply absolute;
        left: 0.5rem;
    }
    .before {
        top: -1rem;
    }
    .after {
        bottom: -1.25rem;
    }
}
</style>