<template>
    <div
        class="
            fixed
            w-full
            h-screen
            top-0
            flex flex-col
            justify-center
            items-center
            z-50
            loader
        "
    >
        <div class="flex flex-col">
            <div class="font-light name w-40">HabardiT.com</div>
            <div class="code-line flex gap-1 my-1">
                <div class="code-segment w-16"></div>
                <div class="code-segment w-4"></div>
            </div>
            <div class="code-line flex gap-1 my-1">
                <div class="w-6"></div>
                <div class="code-segment w-8"></div>
                <div class="code-segment w-4"></div>
                <div class="code-segment w-4"></div>
            </div>
            <div class="code-line flex gap-1 my-1">
                <div class="w-12"></div>
                <div class="code-segment w-12"></div>
                <div class="code-segment w-16"></div>
                <div class="code-segment w-4"></div>
            </div>
            <div class="code-line flex gap-1 my-1">
                <div class="w-12"></div>
                <div class="code-segment w-6"></div>
                <div class="code-segment w-8"></div>
                <div class="code-segment w-20"></div>
            </div>
            <div class="code-line flex gap-1 my-1">
                <div class="w-6"></div>
                <div class="code-segment w-16"></div>
                <div class="code-segment w-12"></div>
                <div class="code-segment w-2"></div>
            </div>
            <div class="code-line flex gap-1 my-1">
                <div class="code-segment w-6"></div>
                <div class="code-segment w-12"></div>
            </div>
        </div>
        <!-- <div
            class="code-line grid my-1 grid-flow-col gap-2"
            v-for="(codeLine, index) in codelines"
            :key="index"
        >
            <div
                class="code-segment"
                v-for="(codeSeg, index) in codeLine"
                :key="index"
                :class="codeSeg"
            ></div>
        </div> -->
    </div>
</template>

<script>
import anime from "animejs";
import _ from "lodash";
export default {
    props: {
        hideLoader: { type: Function },
    },
    computed: {
        codelines() {
            const arr = ["w-16", "w-8", "w-12", "w-8"];
            let pArr = [];
            for (let i = 0; i < 4; i++) {
                pArr.push(_.shuffle(arr));
            }
            return pArr;
        },
    },
    methods: {
        completed() {
            // return;
            this.hideLoader();
            anime({ targets: ".name", scale: 0, opacity: [1, 0] });
        },
    },
    mounted() {
        let timeline1 = anime.timeline({
            targets: ".code-segment:nth-child(even)",
            complete: this.completed,
        });
        let timeline2 = anime.timeline({
            targets: ".code-segment:nth-child(odd)",
        });
        let timeline3 = anime.timeline({ targets: ".name" });
        timeline3.add({
            delay: 500,
            targets: ".name",
            scale: [3, 1],
            opacity: [0, 1],
        });
        timeline1
            .add({
                // translateY: [-100, 0],
                // translateX: [-100, 0],
                scaleX: [0, 1],
                background: "#455a64",
                // opacity: [0, 1],
                duration: 600,
                delay: anime.stagger(120, {
                    start: 400,
                    from: "center",
                    direction: "reverse",
                }),
            })
            .add(
                {
                    background: "#2e7d32",
                    delay: anime.stagger(100, { from: "center" }),
                },
                "-=600"
            );
        timeline2
            .add({
                // translateY: [100, 0],
                // translateX: [100, 0],
                scaleX: [0, 1],
                background: "#d33d55",
                // opacity: [0, 1],
                duration: 600,
                delay: anime.stagger(120, {
                    start: 500,
                    from: "center",
                }),
            })
            .add(
                {
                    background: "#2e7d32",
                    delay: anime.stagger(100, { from: "center" }),
                },
                "-=600"
            );
    },
};
</script>

<style lang="scss" scoped>
.loader {
    background: #1d1d1d;
}
.code-segment {
    @apply h-2 rounded-xl;
}
</style>