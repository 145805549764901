<template>
    <div class="relative max-w-4xl mx-auto p-8 mt-28">
        <section-tag tag="work"></section-tag>
        <div class="mx-auto text-center py-8">
            <project
                v-for="(project, index) in [...projects].reverse()"
                :key="index"
                :num="index"
                :project="project"
                :rtl="index % 2 == 0 ? false : true"
                :class="{ 'sm:mb-44 mb-20': index + 1 != projects.length }"
            ></project>
        </div>
    </div>
</template>

<script>
import SectionTag from "./section-tag.vue";
import Project from "./project.vue";

export default {
    components: {
        SectionTag,
        Project,
    },

    data: () => ({
        projects: [
            {
                name: "Taqat",
                img: require("../assets/img/taqat.jpg"),
                description:
                    "A platform that brings jobseekers and employers together, provides training programs and certifications for all.",
                techs: [
                    {
                        name: "Liferay",
                        href: "",
                    },
                    {
                        name: "Spring",
                        href: "",
                    },
                    {
                        name: "Bootstrap",
                        href: "",
                    },

                    {
                        name: "SOAP",
                        href: "",
                    },
                    {
                        name: "jQuery",
                        href: "",
                    },
                    {
                        name: "HTML",
                        href: "",
                    },
                    {
                        name: "CSS",
                        href: "",
                    },
                    {
                        name: "JavaScript",
                        href: "",
                    },
                ],
            },
            {
                name: "NCP+ Intranet",
                img: require("../assets/img/ncpintranet.jpg"),
                description:
                    "National Center of Privatization internal website for departments and employees to maintain easier communication, enhanced collaboration and resource sharing",
                techs: [
                    {
                        name: "Sharepoint",
                        href: "",
                    },
                    {
                        name: "Angular.js",
                        href: "",
                    },
                    {
                        name: ".Net",
                        href: "",
                    },
                    {
                        name: "Masonry.js",
                        href: "",
                    },
                    {
                        name: "SASS",
                        href: "",
                    },
                    {
                        name: "Bootstrap",
                        href: "",
                    },
                ],
            },
            {
                name: "Taqdeer PPA",
                img: require("../assets/img/taqdeer.png"),
                description:
                    "A public pension agency website that serves retired citizens by providing them with offers, experience, funds and activities",
                techs: [
                    {
                        name: "Liferay",
                        href: "",
                    },
                    {
                        name: "FTL",
                        href: "",
                    },
                    {
                        name: "Bootstrap",
                        href: "",
                    },
                    {
                        name: "jQuery",
                        href: "",
                    },
                    {
                        name: "HTML",
                        href: "",
                    },
                    {
                        name: "SASS",
                        href: "",
                    },
                    {
                        name: "JavaScript",
                        href: "",
                    },
                ],
            },
            {
                name: "Najiz Execution",
                img: require("../assets/img/najiz.png"),
                description:
                    "A ministry of justice execution platform that serves requesters to submit an issue of judicial order, also serves judicial employees to manage requests",
                techs: [
                    {
                        name: ".Net",
                        href: "",
                    },
                    {
                        name: "Vue.js",
                        href: "",
                    },
                    {
                        name: "REST",
                        href: "",
                    },
                    {
                        name: "IIS",
                        href: "",
                    },
                    {
                        name: "Highcharts",
                        href: "",
                    },
                    {
                        name: "Webpack\n",
                        href: "",
                    },
                    {
                        name: "Gulp.js",
                        href: "",
                    },
                    {
                        name: "SASS",
                        href: "",
                    },
                    {
                        name: "TFS",
                        href: "",
                    },
                ],
            },
            {
                name: "Admin Dashboard",
                img: require("../assets/img/admin.png"),
                description:
                    "An administration dashboard for Border Control Middleware in National Information Center, used to manage ports and terminals. as well as serve statistical information and charts",
                techs: [
                    {
                        name: "React.js",
                        href: "",
                    },
                    {
                        name: "SpringBoot",
                        href: "",
                    },
                    {
                        name: "REST",
                        href: "",
                    },
                    {
                        name: "Redux",
                        href: "",
                    },
                    {
                        name: "SASS",
                        href: "",
                    },
                    {
                        name: "Chart.js",
                        href: "",
                    },
                ],
            },
        ],
    }),
};
</script>
 
<style lang="scss" scoped>
.work {
    &::before,
    &::after {
        font-family: $cursive-font;
        @apply text-red-900 text-2xl;
    }
    &::before {
        content: "<work>";
        position: absolute;
        left: -0.5rem;
        top: -1rem;
    }
    &::after {
        content: "</work>";
        position: absolute;
        left: -0.5rem;
        bottom: -1.25rem;
    }
}
</style>