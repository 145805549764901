<template>
    <div class="pb-44">
        <loader ref="loader" :hideLoader="hideLoader" v-if="loading" />
        <div ref="home">
            <about></about>
            <work></work>
        </div>
    </div>
</template>

<script>
import Work from "../components/work";
import About from "../components/about";
import Loader from "../components/loader";
import anime from "animejs";

export default {
    name: "Home",
    data() {
        return {
            loading: true,
        };
    },
    methods: {
        hideLoader() {
            const {
                home,
                loader: { $el },
            } = this.$refs;
            console.log($el);
            anime({
                targets: $el,
                opacity: [1, 0],
                delay: 50,
                duration: 500,
                changeComplete: () => {
                    this.loading = false;
                },
            });
            anime({
                targets: home,
                translateY: [100, 0],
                // opacity: [0, 1],
                duration: 500,
            });
        },
    },
    components: { About, Work, Loader },
};
</script>
