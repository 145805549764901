<template>
    <main>
        <router-view />
    </main>
</template>

<script>
export default {
    name: "App",

    data: () => ({
        //
    }),
    mounted() {},
};
</script>
<style lang="scss">
@tailwind base;

@tailwind components;

@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=La+Belle+Aurore&family=Red+Hat+Mono:ital,wght@0,300;0,400;1,300;1,400&display=swap");
body {
    font-family: "Fira Sans", sans-serif !important ;
    background: $bg-background;
    color: $text-color;
}
html {
    overflow-x: hidden;
    overflow-y: overlay;
}
* {
    // transition: all ease 0.1s;
}
</style>