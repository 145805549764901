<template>
    <div
        class="
            relative
            max-w-4xl
            mx-auto
            mt-24
            rounded
            flex
            justify-between
            sm:flex-row
            flex-col-reverse
            gap-4
            py-2
        "
    >
        <section-tag tag="about"></section-tag>
        <div
            class="
                mx-auto
                text-center
                sm:text-start
                flex flex-col
                justify-center
            "
            ref="avatarText"
        >
            <h1 class="sm:text-5xl text-2xl font-thin">
                Turki<span class="last-name"> Alhabardi</span>
            </h1>
            <h2 class="sm:text-3xl text-lg font-thin job-title">
                Software Engineer
            </h2>
        </div>
        <div class="sm:w-52 w-32 sm:h-96 relative mx-auto">
            <img
                src="../assets/img/me.jpg"
                alt="Turki"
                class="rounded shadow-lg relative z-10"
            />
            <div class="absolute top-1 -right-12 sm:grid gap-1 hidden">
                <div class="w-10 h-1 rounded bg-yellow-300"></div>
                <div class="w-8 h-1 rounded bg-red-300"></div>
                <div class="w-12 h-1 rounded bg-indigo-300"></div>
                <div class="w-6 h-1 rounded bg-pink-600"></div>
            </div>
        </div>
    </div>
    <!-- <div class="about max-w-xl mx-auto p-8 mt-24 shadow rounded">
        <section-tag tag="about"></section-tag>
        <div class="mx-auto text-center" ref="avatarText">
            <div
                class="
                    avatar-placeholder
                    mb-3
                    sm:w-64
                    w-40
                    sm:h-64
                    h-40
                    mx-auto
                "
            >
                <img
                    src="https://media-exp1.licdn.com/dms/image/C4E03AQGEpfN1RFBhuA/profile-displayphoto-shrink_800_800/0/1608799329205?e=1646870400&v=beta&t=xYpOE8GIiz4KxY8hPOCey_lIHcBYw0cYgTfMCsLDK84"
                    alt="Turki"
                    class="rounded relative z-10"
                />
            </div>
            <h1 class="sm:text-4xl text-2xl font-extralight">
                Turki Alhabardi
            </h1>
            <h2 class="sm:text-2xl text-lg sm:mt-2 font-light job-title">
                Software Engineer
            </h2>
        </div>
        <div class="container mx-auto mt-12" ref="description">
            <p
                class="
                    sm:text-lg
                    text-base
                    font-light
                    mt-2
                    whitespace-pre-line
                    sm:leading-10
                    leading-6
                    text-justify
                "
            >
                I started my journey with software development at the age of 12.
                It began with portraying a snowfall scene. An animation that my
                128MB RAM couldn't handle. A frustrating problem, that upgrading
                to 256MB RAM couldn't solve. A year later, I was able to make it
                work. By modifying my project to make use of
                &lt;ActionScript&gt; in Macromedia Flash. Since then, I've had
                this <span class="text-yellow-200">DRIVE</span> for software
                development.
            </p>
        </div>
    </div> -->
</template>

<script>
import sectionTag from "./section-tag.vue";
import { gsap } from "gsap";

export default {
    components: {
        sectionTag,
    },
    mounted() {
        // const { avatarText, description } = this.$refs;
        // gsap.timeline({
        //     scrollTrigger: {
        //         trigger: this.$el,
        //         start: "top bottom",
        //         end: `${innerHeight - 300}`,
        //         scrub: 1,
        //     },
        // })
        //     .to(this.$el.children, {
        //         duration: 1,
        //     })
        //     .to(this.$el.children, {
        //         translateY: -200,
        //         duration: 0.5,
        //     });
    },
};
</script>

<style lang="scss" scoped>
.last-name {
    background: $last-name;
}
</style>