<template>
    <div :class="`cont-${num}`" :dir="rtl ? 'rtl' : 'ltr'">
        <div class="project sm:grid hidden" ref="project">
            <div class="image">
                <img :src="project.img" class="rounded h-full shadow-lg" />
            </div>
            <div class="description" ref="description">
                <h1 class="text-end text-2xl mb-2 project-title">
                    {{ project.name }}
                </h1>
                <div class="project-description shadow-lg p-4 w-full rounded">
                    <p class="text-end mb-0">
                        {{ project.description }}
                    </p>
                </div>
            </div>
            <div
                class="
                    techs
                    text-sm
                    p-2
                    relative
                    flex flex-col
                    justify-center
                    text-start
                "
            >
                {
                <p class="text-center mb-0 px-4 whitespace-pre-line" dir="ltr">
                    <span
                        v-for="(tech, index) in project.techs"
                        :key="tech.name"
                    >
                        {{ tech.name
                        }}{{ index == project.techs.length - 1 ? "" : "," }}
                    </span>
                </p>
                }
            </div>
        </div>
        <div class="sm:hidden grid project-sm" dir="ltr" ref="projectSm">
            <h1 class="text-start text-xl mb-2 project-title">
                {{ project.name }}
            </h1>
            <div class="image">
                <img :src="project.img" class="rounded-t" />
            </div>
            <div class="description -mt-1" ref="descriptionSm">
                <div
                    class="project-description bg-opacity-90 p-4 w-full rounded"
                >
                    <p class="text-start mb-0 text-sm">
                        {{ project.description }}
                    </p>
                </div>
            </div>
            <div
                class="
                    techs
                    sm:text-sm
                    text-xs
                    p-2
                    relative
                    flex flex-col
                    justify-center
                    text-start
                    mt-1
                "
            >
                <p
                    class="text-center mb-0 flex justify-between gap-1"
                    dir="ltr"
                >
                    <span v-for="tech in project.techs" :key="tech.name">
                        {{ tech.name }}
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import anime from "animejs";

export default {
    components: {},
    props: {
        project: {
            type: Object,
            required: true,
        },
        num: {
            required: true,
        },
        rtl: {
            required: true,
        },
    },
    data: () => ({
        entryObj: 0,
        entryAnime: {},
        exitAnime: {},
        entered: false,
    }),
    methods: {
        enterAnimation() {
            if (this.exitAnime.completed || !this.exitAnime.began)
                this.entryAnime.play();
            else {
                this.exitAnime.finished.then(() => {
                    this.entryAnime.play();
                });
            }
        },
        exitAnimation() {
            if (this.entryAnime.completed || !this.entryAnime.began)
                this.exitAnime.play();
            else {
                this.entryAnime.finished.then(() => {
                    this.exitAnime.play();
                });
            }
        },
    },
    watch: {
        entryObj(newVal, oldVal) {
            // console.log(newVal.intersectionRatio);
            if (newVal.intersectionRatio > 0.15 && this.entered) return;
            const differenceRatio =
                newVal.intersectionRatio - (oldVal.intersectionRatio || 0);
            // console.log(differenceRatio);
            if (differenceRatio > 0 && !this.entered) {
                this.enterAnimation();
            } else if (differenceRatio < 0 && this.entered) {
                this.exitAnimation();
            }

            // if()
        },
    },
    mounted() {
        // return;
        let observer = new IntersectionObserver(
            (entries) => {
                this.entryObj = entries[0];
                entries[0].intersectionRect.top;
                entries[0].boundingClientRect;
            },
            { threshold: _.range(0, 1, 0.03) }
        );
        observer.observe(this.$el);
        const self = this;
        const { project, description } = this.$refs;
        console.log(this.$refs);
        this.exitAnime = anime
            .timeline({
                autoplay: false,
                easing: "easeInOutExpo",
                duration: 600,
                begin: function (anim) {
                    self.entered = false;
                },
            })
            .add({
                targets: [project],
                scale: [1, 0.5],
                opacity: [1, 0],
            })
            .add(
                {
                    targets: [description],
                    translateX: [0, this.rtl ? -700 : 700],
                },
                "-=600"
            );

        this.entryAnime = anime
            .timeline({
                autoplay: false,
                easing: "easeInOutExpo",
                duration: 600,
                begin: function (anim) {
                    self.entered = true;
                },
            })
            .add({
                targets: [project],
                scale: [0.5, 1],
                opacity: [0, 1],
            })
            .add(
                {
                    targets: [description],
                    translateX: [this.rtl ? -700 : 700, 0],
                },
                "-=600"
            );
    },
};
</script>

<style lang="scss" scoped>
.dark {
    .image {
        img {
            filter: grayscale(0.7) brightness(0.7) !important;
        }
    }
}
.project {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 200px;

    .image {
        grid-area: 1/1/3/10;
        img {
            object-fit: cover;
        }
    }
    .description {
        grid-area: 1/7/1/13;
        z-index: 1;
    }
    .techs {
        grid-area: 2/10/2/13;
        font-family: $mono;
    }
}
.project-sm {
    .image {
        img {
            filter: grayscale(0.7) brightness(0.7);
            transition: all ease-in-out 0.2s;
        }
    }
}
.project-title {
    color: $project-title;
}
.project-description {
    background: $project-description;
    color: $description-text;
}
</style>